import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Formik } from "formik";
import htmlParser from 'html-react-parser';
import { useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import { BsTrash } from 'react-icons/bs';
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../common/axios";
import createTournamentSchema, { addPrizeSchema } from "../../formValidationSchemas/createTournament.validate";
import useGet from "../../hooks/useGet";
import { getErrors, hasData, toastDefault } from "../../utils/handler.utils";
import Button from '../Button';
import Checkbox from '../Checkbox';
import CustomInputTypeFile from "../Formik_Form/CustomInputTypeFile";
import FormikErrorMessage from "../Formik_Form/FormikErrorMessage";
import FormikInput from "../Formik_Form/FormikInput";
import FormikTextarea from "../Formik_Form/FormikTextarea/FormikTextarea";
import { useTwInput } from "../Input/useTwInput";
import Loader from "../Loader/Loader";
import ShowErrorAfterSubmit from '../ShowErrorAfterSubmit';


function EditTournament() {
    const location = useHistory()
    const inputStyles = useTwInput()
    let { params } = useRouteMatch();
    const [isShowAddPrizeForm, setIsShowAddPrizeForm] = useState(false)
    const tournamentId = params.id
    const [tournamentData, loadingData, error] = useGet(`admin/tournament/${tournamentId}`)
    const data = tournamentData?.tournament
    const prizes = tournamentData?.prizes
    const rules = data?.rules


    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [rulesState, setRulesState] = useState(EditorState.createEmpty())

    useEffect(() => {
        if (hasData(data?.prize)) {
            setEditorState(
                EditorState.createWithContent(convertFromHTML(data?.prize))
            )
        }
        if (hasData(data?.rules)) {
            setRulesState(
                EditorState.createWithContent(convertFromHTML(data?.rules))
            )
        }
    }, [data])

    return (
        <div className="md:px-5" >
            <div className="bg-white py-5 mb-5 px-5">

                <h1 className="text-lg font-bold text-black">Edit Tournament</h1>

                <div className="mt-7" >
                    {hasData(data) ? (
                        <Formik
                            initialValues={{
                                title: data?.title,
                                image: data?.image,
                                start_time: data?.start_time,
                                per_kill: data?.per_kill,
                                entry_fee: data?.entry_fee,
                                version: data?.version,
                                map: data?.map,
                                type: data?.type,
                                user_limit: data?.user_limit,
                                total_prize: data?.total_prize,
                                status: data?.status,
                                room_details: data?.room_details,
                                prize: prizes || [],
                                rules: rules,
                                live_link: data?.live_link,
                                is_show_in_home_page: data?.is_show_in_home_page || ''
                            }}
                            validationSchema={createTournamentSchema}
                            onSubmit={(values, actions) => {
                                axiosInstance.post(`/admin/tournament/edit/${tournamentId}`, {
                                    ...values
                                }).then(() => {
                                    toast.success('Tournament updated successfully', toastDefault)
                                    actions.resetForm()
                                    location.goBack()
                                    setEditorState(null)
                                }).catch(err => {
                                    toast.error(getErrors(err, true)[0], toastDefault)
                                    actions.setSubmitting(false)
                                })
                            }}
                        >
                            {({ handleSubmit, isSubmitting, setFieldValue, errors, initialValues, values, touched }) => (
                                <form onSubmit={handleSubmit} className="space-y-4" >
                                    <div className="form_grid_3 gap-y-5" >
                                        <FormikInput label="Title" name="title" />
                                        <CustomInputTypeFile />
                                        <FormikInput label="Start Time" name="start_time" type="datetime-local" />
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 sm:gap-x-5 md:gap-x-4 gap-y-5" >
                                        <FormikInput label="Per kill" name="per_kill" />
                                        <FormikInput label="Entry fee" name="entry_fee" />
                                        <FormikInput label="Version" name="version" />
                                        <FormikInput label="Total Prize" name="total_prize" />
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 sm:gap-x-5 md:gap-x-4 gap-y-5" >
                                        <FormikInput label="Map" name="map" />
                                        {/* <FormikInput label="Type" name="type" /> */}
                                        <div>
                                            <label
                                                htmlFor="type"
                                                className={`text-slate-500 text-sm dark:text-slate-200 font-semibold mb-1 inline-block select-none`}
                                            >
                                                Match Type
                                            </label>
                                            <select id="type" name="type" defaultValue={data?.type} onChange={e => setFieldValue('type', e.target.value)} className={`${inputStyles} ${touched['type'] && errors['type'] ? '!border-red-600' : ''}`} >
                                                <option value="">Select type</option>
                                                <option value="solo">Solo</option>
                                                <option value="duo">Duo</option>
                                                <option value="squad">Squad</option>
                                            </select>
                                            <FormikErrorMessage name="type" />
                                        </div>
                                        <FormikInput label="User limit" name="user_limit" />
                                        <div>
                                            <label
                                                htmlFor="status"
                                                className={`text-slate-500 text-sm dark:text-slate-200 font-semibold mb-1 inline-block select-none`}
                                            >
                                                Status
                                            </label>
                                            <select id="status" defaultValue={data?.status} name="status" onChange={e => setFieldValue('status', e.target.value)} className={`${inputStyles} ${touched['status'] && errors['status'] ? '!border-red-600' : ''}`} >
                                                <option value="">Select status</option>
                                                <option value="open">Open</option>
                                                <option value="running">Running</option>
                                                <option value="ended">Ended</option>
                                            </select>
                                            <FormikErrorMessage name="status" />
                                        </div>
                                    </div>

                                    <div>
                                        <FormikInput name='live_link' />
                                    </div>

                                    <FormikTextarea name="room_details" label="Room details" rows={5} />
                                    <div>
                                        <label
                                            className={`text-slate-500 text-sm dark:text-slate-200 font-semibold mb-1 inline-block select-none`}
                                        >
                                            Rules
                                        </label>
                                        <Editor
                                            editorState={rulesState}
                                            editorStyle={{
                                                height: 200,
                                            }}
                                            wrapperStyle={{
                                                border: `1px solid ${errors['rules'] && touched['rules'] ? 'red' : '#dcdcf3'}`,
                                                borderRadius: 6,
                                                background: '#fff'
                                            }}
                                            onEditorStateChange={(e) => {
                                                setRulesState(e)
                                                setFieldValue('rules', convertToHTML(e.getCurrentContent()))
                                            }}
                                        />
                                        <FormikErrorMessage name="rules" />
                                    </div>
                                    <div className="mty-5" >
                                        <Checkbox defaultChecked={data?.is_show_in_home_page === 1 ? true : false} label="Show in home page" onChange={e => {
                                            setFieldValue('is_show_in_home_page', e.target.checked ? 1 : 0)

                                        }} />
                                    </div>

                                    <div className={`my-5 mb-3 border ${errors['prize'] ? 'border-red-500' : 'border-gray-200'} rounded-md`} >
                                        <div className="flex items-center justify-between  px-5 py-3">
                                            <h3 className="text-xl font-bold" >Tournament Prize</h3>
                                            <Button onClick={() => setIsShowAddPrizeForm(prev => !prev)}>
                                                {isShowAddPrizeForm ? 'Close' : "Add Prize"}
                                            </Button>
                                        </div>
                                        {hasData(values.prize) && <div className="px-8 mb-4" >
                                            <table className="w-full border-collapse text-left" >
                                                <thead>
                                                    <tr>
                                                        <th className="p-2">Place</th>
                                                        <th className="p-2">Name</th>
                                                        <th className="p-2">Amount</th>
                                                    </tr>
                                                </thead>
                                                {values.prize.map((p, index) => (
                                                    <tr
                                                        key={index}
                                                        className="bg-gray-50"
                                                    >
                                                        <td className="p-2" >
                                                            {p.place}
                                                        </td>
                                                        <td className="p-2" >
                                                            {htmlParser(p.name)}
                                                        </td>
                                                        <td className="p-2" >
                                                            <div className="space-x-2" >
                                                                <span>{p.amount}</span>
                                                                <Button size="small" color="red" onClick={() => {
                                                                    setFieldValue('prize', values.prize.filter(pr => {
                                                                        return pr.place !== p.place
                                                                    }))
                                                                }}>
                                                                    <BsTrash />
                                                                </Button>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>}
                                        {isShowAddPrizeForm && <Formik
                                            onSubmit={(val, actn) => {

                                                if (val.name === '<p></p>') {
                                                    actn.setFieldError('name', 'Name is a required filed')
                                                    actn.setSubmitting(false)
                                                    return;
                                                }


                                                setFieldValue('prize', [...values.prize, val])
                                                setEditorState(null)
                                                setIsShowAddPrizeForm(false)
                                            }}
                                            validationSchema={addPrizeSchema}
                                            initialValues={{
                                                place: '',
                                                amount: '',
                                                name: ''
                                            }}
                                        >
                                            {(prizeForm) => (
                                                <div className="p-5 space-y-3 bg-gray-50">
                                                    <label
                                                        className={`text-slate-500 text-sm dark:text-slate-200 font-semibold mb-1 inline-block select-none`}
                                                    >
                                                        Name
                                                    </label>
                                                    <div>
                                                        <Editor
                                                            editorState={editorState}
                                                            editorStyle={{
                                                                height: 200,
                                                            }}
                                                            wrapperStyle={{
                                                                border: `1px solid ${prizeForm.errors['name'] && prizeForm.touched['name'] ? 'red' : '#dcdcf3'}`,
                                                                borderRadius: 6,
                                                                background: '#fff'
                                                            }}
                                                            onEditorStateChange={(e) => {
                                                                setEditorState(e)
                                                                prizeForm.setFieldValue('name', convertToHTML(e.getCurrentContent()))
                                                            }}
                                                        />
                                                        <FormikErrorMessage name="name" />
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-5">
                                                        <div>
                                                            <select onChange={e => {
                                                                prizeForm.setFieldValue('place', e.target.value)
                                                            }} className={`${prizeForm.touched['place'] && prizeForm.errors['place'] ? '!border-red-600' : ''} ${inputStyles}`}>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                            </select>
                                                            <FormikErrorMessage name="place" />
                                                        </div>
                                                        <FormikInput name="amount" placeholder="Price" />
                                                    </div>
                                                    <div>
                                                        <Button type="button" onClick={prizeForm.handleSubmit}>Save</Button>
                                                    </div>
                                                </div>
                                            )}
                                        </Formik>}
                                    </div>

                                    <ShowErrorAfterSubmit
                                        className="mb-0"
                                        touched={touched}
                                        errors={errors}
                                        initialValues={initialValues} />
                                    <Button
                                        // loading={isSubmitting}
                                        type="submit" className="mt-4" >Edit Tournament</Button>
                                </form>
                            )}
                        </Formik>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </div>
    )
}

export default EditTournament
