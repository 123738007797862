import React, { useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../common/axios';
import useGet from '../../hooks/useGet';
import useUpload from '../../hooks/useUpload';
import { getErrors, hasData, toastDefault } from '../../utils/handler.utils';
import Loader from '../Loader/Loader';

import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../../common/editor-tools-config'

const ReactEditorJS = createReactEditorJS()

function EditBanner(props) {
    const history = useHistory()
    const noticeId = props.match.params.id;

    const [loading, setLoading] = useState(null)
    const [data, loadingData, error] = useGet(`admin/blogs/${noticeId}`)
    const [bannerImage, setBannerImage] = useState(data?.banner)
    const { path, uploading } = useUpload(bannerImage)

    const titleRef = useRef(null);
    const imageRef = useRef(null);
    const isActiveRef = useRef(null);
    const editorCore = useRef(null)

    const editPaymentBlogHandler = async (e) => {
        e.preventDefault()
        setLoading(true)

        console.log(titleRef, imageRef, isActiveRef, editorCore)

        const titleValue = titleRef.current.value
        const imageValue = path || data?.image
        const isActiveValue = isActiveRef.current.checked ? 1 : 0
        const descriptionValue = JSON.stringify(await editorCore.current.save())
        
        axiosInstance.post(`/admin/blogs/edit/${noticeId}`, {
            title: titleValue,
            image: imageValue,
            description: descriptionValue,
            is_active: isActiveValue,
        }).then(res => {
            toast.success('Blog updated successfully', toastDefault)

            setTimeout(() => {
                history.push('/blog')
            }, 1500);
        }).catch(err => {
            toast.error(getErrors(err, false, true), toastDefault)
            setLoading(false)
        })
    }

    return (
        <section className="relative container_admin" >
            <div className="bg-white overflow-hidden rounded">
                <div className="px-6 py-3 border-b border-gray-200">
                    <h3 className="text-lg font-bold text-black">
                        Edit Blog
                    </h3>
                </div>
                <div className="py-10 px-4" >
                    <div className="w-full md:w-[70%] min-h-[300px] mx-auto py-6 relative border border-gray-200 px-4">
                        {loadingData && <Loader absolute />}
                        {loading && <Loader absolute />}
                        {
                            hasData(data, loading, error) && (
                                <form onSubmit={editPaymentBlogHandler} >
                                    <div>

                                        <div>
                                            <label htmlFor="title">Title</label>
                                            <input ref={titleRef} id="title" defaultValue={data?.title} className="form_input" type="text" placeholder="Title" required />
                                        </div>


                                        <div>
                                            <label htmlFor="image">Image</label>
                                            <input ref={imageRef} id="image" className="form_input" type="file" onChange={e => setBannerImage(e.target.files[0])} />
                                        </div>

                                        

                                        <div>
                                            <ReactEditorJS
                                                tools={EDITOR_JS_TOOLS}
                                                className="border border-sky-500"
                                                defaultValue={JSON.parse(data?.description)}
                                                onInitialize={(instance) => {
                                                    editorCore.current = instance
                                                }}
                                            />
                                        </div>

                                        

                                        <div className="cursor-pointer" >
                                            <input ref={isActiveRef} id="is_active" defaultChecked={data?.is_active === 1} type="checkbox" className="mr-2" />
                                            <label htmlFor="is_active" className="select-none cursor-pointer">Is Active</label>
                                        </div>





                                        <div className="mt-4">
                                            <button type="submit" disabled={uploading} className="cstm_btn w-full block">Updated Blog</button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withRouter(EditBanner)
