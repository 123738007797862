import ToolboxIcon from './youtube.svg';

export default class YoutubeEmbed {
  /**
   *
   * Get toolbox settings
   *
   * @return {{icon: string, title: string}}
   *
   */
  static get toolbox() {
    return {
      title: 'YouTube',
      icon: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 461.001 461.001" style="enable-background:new 0 0 461.001 461.001;" xml:space="preserve">
   <g>
     <path style="fill:#F61C0D;" d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
       c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
       C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
       c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"/>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   </svg>`,
    };
  }

  /**
   *
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {data: DelimiterData} — previously saved data
   *
   */
  constructor({ data, config, api, readOnly }) {
    this.data = data;
    this.readOnly = readOnly;

    this.wrapper = null;
    this.url = null;
    this.isEdited = false;
  }

  /**
   *
   * Return tool's view
   *
   * @returns {HTMLDivElement}
   * @public
   */
  render() {
    this.wrapper = document.createElement('div');
    const input = document.createElement('input');
    input.value = this.data && this.data.url ? this.data.url : '';
    this.url = input.value;
    input.placeholder = 'Paste YouTube url here...';

    this.wrapper.classList.add('block-wrapper');
    this.wrapper.appendChild(input);
    this._createIframe(input.value);

    input.addEventListener('change', (event) => {
      this.isEdited = true;

      this.url = input.value;
      this._createIframe(input.value);
    });
    return this.wrapper;
  }

  /**
   *
   * Create iframe for YouTube embed
   * @private
   * @param {string} url
   *
   */
  _createIframe(url) {
    let videoId = url.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&]{10,12})/);
    videoId = videoId && videoId[1];
    console.log('video id **** ',videoId)

    if (videoId == null) {
      if (this.isEdited) {
        this.wrapper.querySelector('input').classList.add('invalid');
      }
      return;
    }

    this.wrapper.innerHTML = null;
    const plyrContainer = document.createElement('div');
    plyrContainer.classList.add('video-wrapper');

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}`);
    iframe.setAttribute('allowfullscreen', true);

    plyrContainer.appendChild(iframe);
    this.wrapper.appendChild(plyrContainer);
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   *
   * @return {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Return block data
   *
   * @public
   * @param {HTMLDivElement} blockContent - Block wrapper
   * @returns {object}
   */
  save(blockContent) {
    const input = blockContent.querySelector('input');
    return {
      url: this.url,
    };
  }
}
