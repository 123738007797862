import { useRef, useState } from "react";
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../common/axios";
import { getErrors, hasData, toastDefault } from "../../utils/handler.utils";
import { tournametnPlayersColumns, tournametnsTableColumns } from "../../utils/reactTableColumns";
import Button from "../Button";
import Table from "../react-table/Table";


Modal.setAppElement('#root')


function Tournament() {
    const [totalDataCount, setTotalDataCount] = useState(null)
    const reloadRefFunc = useRef(null)
    const reloadRefFunc2 = useRef(null)
    const [isOpenModal, setIsOpenModal] = useState(false)

    let actionMenu = {
        id: 'edit',
        Header: "Action",
        accessor: 'id',
        Cell: (e) => {
            return <ul className="flex space-x-2 items-center">
                <li>
                    <Link className="cstm_btn_small" to={`/tournament/edit/${e.value}`} >Edit</Link>
                </li>
                <li className="cstm_btn_small btn_red" onClick={() => deleteTournament(e.value)}>
                    Delete
                </li>
                <li className="cstm_btn_small" onClick={() => setIsOpenModal(e.value)}>
                    View Players
                </li>
            </ul>
        }
    };
    let withActionMenu = [...tournametnsTableColumns, actionMenu]

    const addKillPrize = async (tournamentId) => {
        if(window.confirm('Are you sure want to add kills prize?')) {
            toast.promise(
                axiosInstance.get(`/admin/tournament/add-kill-prize/${tournamentId}`),
                {
                    pending: 'Adding kill prize...',
                    error: {
                        render(err) {
                            console.log(err);
                            return getErrors(err.data, false, true)
                        }
                    },
                    success: {
                        render() {
                            reloadRefFunc.current()
                            return 'Kills prize successfully'
                        }
                    }
                },
                toastDefault
            )
        }
    }

    const deleteTournament = async (order_id) => {
        if (window.confirm('Are you sure?')) {
            toast.promise(
                axiosInstance.post(`/admin/tournament/delete/${order_id}`),
                {
                    pending: 'Deleting tournament...',
                    error: {
                        render(err) {
                            console.log(err);
                            return getErrors(err.data, false, true)
                        }
                    },
                    success: {
                        render() {
                            reloadRefFunc.current()
                            return 'Tournament deleted successfully'
                        }
                    }
                },
                toastDefault
            )
        }
    }

    return (
        <>
            <Modal
                isOpen={isOpenModal !== false ? true : false}
                onRequestClose={() => setIsOpenModal(false)}
                style={{
                    overlay: {
                        background: 'rgba(0,0,0,0.7)',
                        color: '#333'
                    }
                }}
            >
                <div className="mb-4 flex justify-end"><Button onClick={() => addKillPrize(isOpenModal)}>Add Kill prize</Button></div>
                <Table
                    url={`/v1/tournaments/participants/${isOpenModal}`}
                    tableTitle="Tournaments Player"
                    tableId={`tournament-player-table`}
                    selectData={(res) => {

                        return {
                            data: res.data.data,
                        }
                    }}
                    reloadRefFunc={reloadRefFunc2}
                    selectError={(err) => getErrors(err, true)[0]}
                    columns={tournametnPlayersColumns}
                    disableGlobalSearch
                    disablePagination
                    disableRowsPerPage
                    enableLocalSearch
                />
                <ToastContainer />
            </Modal>
            <div className="md:px-5" >
                <div className="bg-white py-5 mb-5 px-5">

                    <Table
                        customGlobalSearch={({ addSearchParam, removeSearchParam }) => (
                            // <SearchOrder addSearchParam={addSearchParam} removeSearchParam={removeSearchParam} />
                            <div className="flex justify-end mb-5" >
                                <Link to={'/tournament/create'} className="cstm_btn">Create Tournament</Link>
                            </div>
                        )}
                        reloadRefFunc={reloadRefFunc}
                        tableTitle="Tournaments"
                        tableSubTitle={hasData(totalDataCount) && `Total result: ${totalDataCount}`}
                        globalSearchPlaceholder="Product id or user id"
                        tableId="tournaments_table"
                        url="/admin/tournaments"
                        selectData={(res) => {
                            setTotalDataCount(res.data.data.order_count)

                            return {
                                data: res.data.data.tournaments,
                                total: res.data.data.count,
                            }
                        }}
                        queryString="order_id"
                        disableGlobalSearch
                        selectError={(err) => getErrors(err, true)[0]}
                        columns={withActionMenu}
                    />
                </div>
            </div>
        </>
    )
}

export default Tournament