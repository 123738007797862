import { convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Formik } from "formik";
import htmlParser from 'html-react-parser';
import { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { BsTrash } from 'react-icons/bs';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import axiosInstance from '../../common/axios';
import createTournamentSchema, { addPrizeSchema } from "../../formValidationSchemas/createTournament.validate";
import { getErrors, hasData, toastDefault } from '../../utils/handler.utils';
import Button from '../Button';
import CustomInputTypeFile from "../Formik_Form/CustomInputTypeFile";
import FormikCheckbox from '../Formik_Form/FormikCheckbox/FormikCheckbox';
import FormikErrorMessage from "../Formik_Form/FormikErrorMessage";
import FormikInput from "../Formik_Form/FormikInput";
import FormikTextarea from "../Formik_Form/FormikTextarea/FormikTextarea";
import { useTwInput } from "../Input/useTwInput";
import ShowErrorAfterSubmit from '../ShowErrorAfterSubmit';

function CreateTournament() {
    const inputStyles = useTwInput()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [reulesState, setReulesState] = useState(EditorState.createEmpty())
    const [isShowAddPrizeForm, setIsShowAddPrizeForm] = useState(false)
    const location = useHistory();
    const _editor_state_to_html = editorState && convertToHTML(editorState.getCurrentContent())

    return (
        <div className="md:px-5" >
            <div className="bg-white py-5 mb-5 px-5">

                <h1 className="text-lg font-bold text-black">Create New Tournament</h1>

                <div className="mt-7" >
                    <Formik
                        initialValues={{
                            title: '',
                            image: '',
                            start_time: '',
                            per_kill: '',
                            total_prize: '',
                            entry_fee: '',
                            version: '',
                            map: '',
                            type: '',
                            user_limit: '',
                            status: '',
                            room_details: '',
                            rules: '',
                            is_show_in_home_page: '',
                            live_link: '',
                            prize: []
                        }}
                        validationSchema={createTournamentSchema}
                        onSubmit={(values, actions) => {
                            if (values.prize === '<p></p>') {
                                actions.setFieldError('prize', 'Prize is a required filed')
                                actions.setSubmitting(false)
                                return;
                            }
                            if (values.rules === '<p></p>') {
                                actions.setFieldError('rules', 'Rules is a required filed')
                                actions.setSubmitting(false)
                                return;
                            }
                            axiosInstance.post('/admin/tournament/create', {
                                ...values,
                                is_show_in_home_page: values.is_show_in_home_page ? 1 : 0
                            }).then(() => {
                                toast.success('Tournament created successfully', toastDefault)
                                actions.resetForm()
                                setEditorState(null)
                                location.goBack()
                            }).catch(err => {
                                toast.error(getErrors(err, true)[0], toastDefault)
                                actions.setSubmitting(false)
                            })
                        }}
                    >
                        {({ handleSubmit, isSubmitting, setFieldValue, initialValues, errors, values, touched }) => {
                            return (
                                <form onSubmit={handleSubmit} className="space-y-4" >
                                    <div className="form_grid_3 gap-y-5" >
                                        <FormikInput label="Title" name="title" />
                                        <CustomInputTypeFile />
                                        <FormikInput label="Start Time" name="start_time" type="datetime-local" />
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 sm:gap-x-5 md:gap-x-4 gap-y-5" >
                                        <FormikInput label="Per kill" name="per_kill" />
                                        <FormikInput label="Entry fee" name="entry_fee" />
                                        <FormikInput label="Version" name="version" />
                                        <FormikInput label="Total Prize" name="total_prize" />
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 sm:gap-x-5 md:gap-x-4 gap-y-5" >
                                        <FormikInput label="Map" name="map" />
                                        {/* <FormikInput label="Type" name="type" /> */}
                                        <div>
                                            <label
                                                htmlFor="type"
                                                className={`text-slate-500 text-sm dark:text-slate-200 font-semibold mb-1 inline-block select-none`}
                                            >
                                                Match Type
                                            </label>
                                            <select id="type" name="type" onChange={e => setFieldValue('type', e.target.value)} className={`${inputStyles} ${touched['type'] && errors['type'] ? '!border-red-600' : ''}`} >
                                                <option value="">Select type</option>
                                                <option value="solo">Solo</option>
                                                <option value="duo">Duo</option>
                                                <option value="squad">Squad</option>
                                            </select>
                                            <FormikErrorMessage name="type" />
                                        </div>
                                        <FormikInput label="User limit" name="user_limit" />
                                        <div>
                                            <label
                                                htmlFor="status"
                                                className={`text-slate-500 text-sm dark:text-slate-200 font-semibold mb-1 inline-block select-none`}
                                            >
                                                Status
                                            </label>
                                            <select id="status" name="status" onChange={e => setFieldValue('status', e.target.value)} className={`${inputStyles} ${touched['status'] && errors['status'] ? '!border-red-600' : ''}`} >
                                                <option value="">Select status</option>
                                                <option value="open">Open</option>
                                                <option value="running">Running</option>
                                                <option value="ended">Ended</option>
                                            </select>
                                            <FormikErrorMessage name="status" />
                                        </div>
                                    </div>

                                    <FormikTextarea name="room_details" label="Room details" rows={5} />
                                    <div>
                                        <FormikInput name="live_link" label="Live link" />
                                    </div>
                                    <div>
                                        <label
                                            className={`text-slate-500 text-sm dark:text-slate-200 font-semibold mb-1 inline-block select-none`}
                                        >
                                            Rules
                                        </label>
                                        <Editor
                                            editorState={reulesState}
                                            editorStyle={{
                                                height: 200,
                                                padding: '0 10px'
                                            }}
                                            wrapperStyle={{
                                                border: `1px solid ${errors['rules'] && touched['rules'] ? 'red' : '#dcdcf3'}`,
                                                borderRadius: 6,
                                                background: '#fff',
                                            }}
                                            placeholder="Rules"
                                            onEditorStateChange={(e) => {
                                                setReulesState(e)
                                                setFieldValue('rules', convertToHTML(e.getCurrentContent()))
                                            }}
                                        />
                                        <FormikErrorMessage name="rules" />
                                    </div>


                                    <div className="my-5" >
                                        <FormikCheckbox value="is_show_in_home_page" name="is_show_in_home_page" label="Show in home page" />
                                    </div>
                                    <div className={`my-5 mb-3 border ${errors['prize'] ? 'border-red-500' : 'border-gray-200'} rounded-md`} >
                                        <div className="flex items-center justify-between  px-5 py-3">
                                            <h3 className="text-xl font-bold" >Tournament Prize</h3>
                                            <Button onClick={() => setIsShowAddPrizeForm(prev => !prev)}>
                                                {isShowAddPrizeForm ? 'Close' : "Add Prize"}
                                            </Button>
                                        </div>
                                        {hasData(values?.prize) && <div className="px-8 mb-4" >
                                            <table className="w-full border-collapse text-left" >
                                                <thead>
                                                    <tr>
                                                        <th className="p-2">Place</th>
                                                        <th className="p-2">Name</th>
                                                        <th className="p-2">Amount</th>
                                                    </tr>
                                                </thead>
                                                {values.prize.map((p, index) => (
                                                    <tr
                                                        key={index}
                                                        className="bg-gray-50"
                                                    >
                                                        <td className="p-2" >
                                                            {p.place}
                                                        </td>
                                                        <td className="p-2" >
                                                            {htmlParser(p.name)}
                                                        </td>
                                                        <td className="p-2" >
                                                            <div className="space-x-2" >
                                                                <span>{p.amount}</span>
                                                                <Button size="small" color="red" onClick={() => {
                                                                    setFieldValue('prize', values.prize.filter(pr => {
                                                                        return pr.place !== p.place
                                                                    }))
                                                                }}>
                                                                    <BsTrash />
                                                                </Button>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>}
                                        {isShowAddPrizeForm && <Formik
                                            onSubmit={(val, actn) => {

                                                if (val.name === '<p></p>') {
                                                    actn.setFieldError('name', 'Name is a required filed')
                                                    actn.setSubmitting(false)
                                                    return;
                                                }


                                                setFieldValue('prize', [...values.prize, val])
                                                setEditorState(null)
                                                setIsShowAddPrizeForm(false)
                                            }}
                                            validationSchema={addPrizeSchema}
                                            initialValues={{
                                                place: '',
                                                amount: '',
                                                name: ''
                                            }}
                                        >
                                            {(prizeForm) => (
                                                <div className="p-5 space-y-3 bg-gray-50">
                                                    <label
                                                        className={`text-slate-500 text-sm dark:text-slate-200 font-semibold mb-1 inline-block select-none`}
                                                    >
                                                        Name
                                                    </label>
                                                    <div>
                                                        <Editor
                                                            editorState={editorState}
                                                            editorStyle={{
                                                                height: 200,
                                                            }}
                                                            wrapperStyle={{
                                                                border: `1px solid ${prizeForm.errors['name'] && prizeForm.touched['name'] ? 'red' : '#dcdcf3'}`,
                                                                borderRadius: 6,
                                                                background: '#fff'
                                                            }}
                                                            onEditorStateChange={(e) => {
                                                                setEditorState(e)
                                                                prizeForm.setFieldValue('name', convertToHTML(e.getCurrentContent()))
                                                            }}
                                                        />
                                                        <FormikErrorMessage name="name" />
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-5">
                                                        <div>
                                                            <select onChange={e => {
                                                                prizeForm.setFieldValue('place', e.target.value)
                                                            }} className={`${prizeForm.touched['place'] && prizeForm.errors['place'] ? '!border-red-600' : ''} ${inputStyles}`}>
                                                                <option value="">Select Place</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                            </select>
                                                            <FormikErrorMessage name="place" />
                                                        </div>
                                                        <FormikInput name="amount" placeholder="Price" />
                                                    </div>
                                                    <div>
                                                        <Button type="button" onClick={prizeForm.handleSubmit}>Save</Button>
                                                    </div>
                                                </div>
                                            )}
                                        </Formik>}
                                    </div>





                                    <div className="!mt-0" >
                                        <FormikErrorMessage name="prize" />
                                    </div>
                                    <ShowErrorAfterSubmit
                                        className="mb-0"
                                        touched={touched}
                                        errors={errors}
                                        initialValues={initialValues} />
                                    <Button
                                        loading={isSubmitting}
                                        type="submit" className="mt-4" >Create Tournament</Button>
                                </form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default CreateTournament
