import { getErrors, toastDefault } from "../../utils/handler.utils";
import { categoryTableColumns } from "../../utils/reactTableColumns";
import Table from "../react-table/Table";
import { Link } from 'react-router-dom'
import { toast } from "react-toastify";
import axiosInstance from "../../common/axios";
import { useRef } from "react";

function Category() {

    const reloadRefFunc = useRef(null)

    const deletePaymentHangdler = (id) => {
        if (window.confirm('Are you sure')) {
            toast.promise(
                axiosInstance.post(`admin/category/delete/${id}`),
                {
                    pending: 'Deleting Category...',
                    error: {
                        render(err) {
                            console.log(err);
                            return getErrors(err.data, false, true)
                        }
                    },
                    success: {
                        render() {
                            // window.location.reload()
                            reloadRefFunc.current()
                            return 'Category deleted successfully'
                        }
                    }
                },
                toastDefault
            )
        }
    }


    let editButton = {
        id: 'action',
        Header: "Action",
        accessor: 'id',
        Cell: (e) => {
            return <ul className="flex space-x-2">
                <Link to={`/category/edit/${e.value}`} className="cstm_btn_small">
                    Edit
                </Link>
                <button className="cstm_btn_small !bg-red-600 hover:!bg-red-700" type="button" onClick={() => deletePaymentHangdler(e.value)}>
                    Delete
                </button>

            </ul>
        }
    };

    const withEditButton = [...categoryTableColumns, editButton]

    return (
        <div className="md:px-5" >
            <div className="bg-white py-5 mb-5 px-5">
                <div className="flex justify-end mb-4">
                    <Link to="/category/add-new" className="cstm_btn_small" >
                        Add Category
                    </Link>
                </div>
                <Table
                    reloadRefFunc={reloadRefFunc}
                    tableTitle="Category"
                    tableId="category_table"
                    url="/admin/category"
                    selectData={(res) => ({
                        data: res.data.data.categories,
                        total: res.data.data.total,
                    })}
                    // disableGlobalSearch
                    selectError={(err) => getErrors(err, true)[0]}
                    columns={withEditButton}
                />
            </div>
        </div>
    )
}

export default Category