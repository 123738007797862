import React, { useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../common/axios';
import useGet from '../../hooks/useGet';
import { getErrors, hasData, toastDefault } from '../../utils/handler.utils';
import Loader from '../Loader/Loader';
function EditVoucher(props) {
    const history = useHistory()
    const packageId = props.match.params.id;

    const [loading, setLoading] = useState(null)
    const [data, loadingData] = useGet(`admin/vouchers/${packageId}`)
    const [products, loadingProducts] = useGet(`admin/vouchers`)

    const product_id = useRef(null);
    const voucherData = useRef(null);
    const is_used = useRef(null);
    const editPackageHandler = (e) => {
        e.preventDefault()
        setLoading(true)
        axiosInstance.post(`/admin/vouchers/update/${packageId}`, {
            product_id: product_id.current.value,
            is_used: is_used.current.checked ? 1 : 0,
            data: voucherData.current.value,
        }).then(res => {
            toast.success('Voucher updated successfully', toastDefault)

            setTimeout(() => {
                history.push('/vouchers')
            }, 1500);
        }).catch(err => {
            toast.error(getErrors(err, false, true), toastDefault)
            setLoading(false)
        })
    }

    return (
        <section className="relative container_admin" >
            <div className="bg-white overflow-hidden rounded">
                <div className="px-6 py-3 border-b border-gray-200">
                    <h3 className="text-lg font-bold text-black">
                        Edit voucher -- {data?.id}
                    </h3>
                </div>
                <div className="py-10 px-4" >
                    <div className="w-full md:w-[70%] min-h-[250px] mx-auto py-6 relative border border-gray-200 px-4">
                        {loadingData || loading || loadingProducts ? <Loader absolute /> : ''}
                        {hasData(data) && hasData(products) &&
                            <form onSubmit={editPackageHandler} >
                                <div>
                                    <div className="form_grid">
                                        <div>
                                            <label htmlFor="name">Product</label>
                                            <select defaultValue={data?.product_id} ref={product_id} className="form_input">
                                                {
                                                    products?.map(product => (
                                                        <option value={product.id}>{product.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="name">Name</label>
                                            <input ref={voucherData} id="name" defaultValue={data?.data} className="form_input" type="text" placeholder="Name" required />
                                        </div>
                                    </div>

                                    <div className="form_grid">
                                        <div>
                                            <label class="inline-flex items-center">
                                                <input ref={is_used} id="in_stock" value="1" className="form-checkbox" type="checkbox" defaultChecked={data?.is_used == 1 ? true: false} />
                                                <span class="ml-2">Is Used</span>
                                            </label>
                                        </div>
                                    
                                    </div>

                                    <div>
                                        <button type="submit" className="cstm_btn w-full block">Edit package</button>
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withRouter(EditVoucher)
