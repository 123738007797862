import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
// import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import Color from 'editorjs-text-color-plugin';
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import YoutubeEmbed from '../components/youtube-embeded'

import axios from "axios";
import urljoin from "url-join";

export const EDITOR_JS_TOOLS = {
    // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
    // paragraph: Paragraph,
    embed: Embed,
    table: Table,
    list: List,
    warning: Warning,
    code: Code,
    linkTool: LinkTool,
    youtubeEmbed: YoutubeEmbed,
    image: {
        class: Image,
        config: {
          uploader: {
            
            uploadByFile(file){
                let formData = new FormData();
                formData.append("image", file);

                return axios({
                    url: urljoin(process.env.REACT_APP_API_ENDPOINT, "v1/upload/image"),
                    method: "POST",
                    data: formData,
                })
                    .then((res) => {
                        return {
                            success: 1,
                            file: {
                              url: urljoin(process.env.REACT_APP_IMAGE_ROOT, 'images', res.data.data.image),
                            }
                        };
                    })
            }
          }
        }
    },
  
    raw: Raw,
    header: Header,
    quote: Quote,
    marker: {
        class: Color,
        config: {
            defaultColor: '#FFBF00',
            type: 'marker', 
        }
    },
    color: {
        class: Color,
        config: {
            colorCollections: ['#000000', '#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF'],
            type: 'text', 
        }
    },
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
  }
