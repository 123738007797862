import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../common/axios';
import useUpload from '../../hooks/useUpload';
import { getErrors, toastDefault } from '../../utils/handler.utils';
import Loader from '../Loader/Loader';
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../../common/editor-tools-config'
import useGet from '../../hooks/useGet';


const ReactEditorJS = createReactEditorJS()

function AddPhysicalProduct() {
    const [categoryData, loadingCategoryData, categoryError] = useGet(`admin/category`)


    const name = useRef(null);
    const image = useRef(null);
    const sale_price = useRef(null);
    const regular_price = useRef(null);
    const is_active_product = useRef(null);
    const is_voucher_available = useRef(null);
    const quantity = useRef(null);
    const category = useRef(null);


    const editorCore = useRef(null)
    const [productLogo, setProductLogo] = useState(null)
    const { path, uploading } = useUpload(productLogo)


    const [loading, setLoading] = useState(null)
    const history = useHistory()

    const createProductHandler = async (e) => {
        e.preventDefault()

        if (!uploading) {
            setLoading(true)
            axiosInstance.post('/admin/physical-product/create', {
                name: name.current.value,
                image: path,
                sale_price: sale_price.current.value,
                category_id: category.current.value,
                regular_price: regular_price.current.value,
                quantity: quantity.current.value,
                is_active: is_active_product.current.checked ? 1 : 0,
                description: JSON.stringify(await editorCore.current.save()),
                is_voucher_available: is_voucher_available.current.checked ? 1 : 0
            }).then(res => {
                toast.success('Product created successfully', toastDefault)

                setTimeout(() => {
                    history.push('/physical-product')
                }, 1500);
            }).catch(err => {
                toast.error(getErrors(err, false, true), toastDefault)
                setLoading(false)
            })
        }
    }

    return (
        <section className="relative container_admin" >
            <div className="bg-white overflow-hidden rounded">
                <div className="px-6 py-3 border-b border-gray-200">
                    <h3 className="text-lg font-bold text-black">
                        Create new product
                    </h3>
                </div>
                <div className="py-10 px-4" >
                    <div className="w-full md:w-[70%] mx-auto py-6 relative border border-gray-200 px-4">
                        {loading && <Loader absolute />}
                        <form onSubmit={createProductHandler} >
                            <div>
                                <div className="form_grid">
                                    <div>
                                        <label htmlFor="name">Name</label>
                                        <input ref={name} id="name" className="form_input" type="text" placeholder="Name" required />
                                    </div>
                                    <div>
                                        <label htmlFor="image">Image</label>
                                        <input ref={image} id="image" className="form_input" type="file" required onChange={e => setProductLogo(e.target.files[0])} />
                                    </div>
                                </div>

                                <div className="form_grid">
                                    <div>
                                        <label htmlFor="sale_price">Sale Price</label>
                                        <input ref={sale_price} id="sale_price" className="form_input" type="number" placeholder="Sale Price" required />
                                    </div>
                                    <div>
                                        <label htmlFor="regular_price">Regular Price</label>
                                        <input ref={regular_price} id="regular_price" className="form_input" type="number" placeholder="Regular Price" required />
                                    </div>
                                </div>
                                <div className="form_grid">
                                    <div>
                                        <label htmlFor="quantity">Quantity</label>
                                        <input ref={quantity} id="quantity" className="form_input" type="number" placeholder="Quantity " required />
                                    </div>
                                    <div>
                                                <label htmlFor="quantity">Category</label>
                                                <select defaultValue="" ref={category} className="form_input">
                                                    {
                                                        categoryData && categoryData?.categories?.map(cat => (
                                                            <option value={cat.id}>{cat.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                </div>

                                

                                <ReactEditorJS
                                    tools={EDITOR_JS_TOOLS}
                                    onInitialize={(instance) => {
                                        editorCore.current = instance
                                    }}
                                />

                                <div className="my-2" >
                                    <label className="py-2 inline-block cursor-pointer select-none">
                                        <input type="checkbox" defaultChecked ref={is_active_product} className="mr-2" />
                                        Is active
                                    </label>
                                </div>

                                <div className="my-2" >
                                    <label className="py-2 inline-block cursor-pointer select-none" >
                                        <input type="checkbox" ref={is_voucher_available} className="mr-2" />
                                        Is Active For Voucher
                                    </label>
                                </div>

                                <div>
                                    <button type="submit" disabled={uploading} className="cstm_btn w-full block">Create Product</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddPhysicalProduct
